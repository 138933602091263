import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Background from "../assets/img/contact.jpg";

function ContactUsPage() {
    const Background1 = { backgroundImage: `url(${Background})` };

    return (
        <div id="contactUsPage" className="contactUsPage pageSettings">
            <Navbar />
            <div className="ipadBK ipad" style={Background1}>
                <div className="blackBlock"></div>
                <div className="textBox">
                    <div className="text">聯絡我們</div>
                    <div className="text2">For Any Needs, Reach Out to Us</div>
                </div>
            </div>
            <section className="mainPage">
                <div className="content">
                    <div className="contactData">
                        <div className="BKImg pc">
                            <img src={Background}></img>
                        </div>
                        <div className="dataInfo">
                            <div className="title pc">
                                <span className="me-4">聯絡我們</span>
                                <wbr />
                                <span className="en pc">Contact Us</span>
                            </div>
                            <div className="subtitle">
                                <div>若您有任何想法或是合作邀約</div>
                                <div>歡迎聯絡我們</div>
                            </div>
                            <div className="item mb-3">地址 / 長安東路二段49號7樓之一</div>
                            <div className="item mb-3">
                                <span className="ipad">聯絡</span>電話 / 02-77560767
                            </div>
                            <div className="item mb-3">信箱 / service@whiteblocktech.com</div>
                            <div className="item">服務時間 / 週一至週五 10:00 - 17:00</div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default ContactUsPage;
