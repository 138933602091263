import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./page/homePage";
import AboutPage from "./page/about";
import ServicesPage from "./page/services";
import CasePage from "./page/case";
import ContactUsPage from "./page/contactUs";

function RouterPage() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/case" element={<CasePage />} />
                <Route path="/contactUs" element={<ContactUsPage />} />
            </Routes>
        </Router>
    );
}
export default RouterPage;
