import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Background from "../assets/img/servicesHalf.jpg";
import Background2 from "../assets/img/services.jpg";
import CaseImg1 from "../assets/img/services1.png";
import CaseImg2 from "../assets/img/services2.png";
import CaseImg3 from "../assets/img/services3.png";
import CaseImg4 from "../assets/img/services4.png";
import CaseImg5 from "../assets/img/services5.png";
import CaseImg6 from "../assets/img/services6.png";

function ServicesPage() {
    const BK1 = { backgroundImage: `url(${Background})` };
    const BK2 = { backgroundImage: `url(${Background2})` };
    const caseImg1 = { backgroundImage: `url(${CaseImg1})` };
    const caseImg2 = { backgroundImage: `url(${CaseImg2})` };
    const caseImg3 = { backgroundImage: `url(${CaseImg3})` };
    const caseImg4 = { backgroundImage: `url(${CaseImg4})` };
    const caseImg5 = { backgroundImage: `url(${CaseImg5})` };
    const caseImg6 = { backgroundImage: `url(${CaseImg6})` };

    const cases = [
        {
            title: "MOOCS平台",
            text: ["打造開放形式的線上學習平台", "學習者可以透過行動裝置與個人電腦進行學習"],
            imgStyle: caseImg1,
        },
        {
            title: "遠距監考系統",
            text: ["一套結合視訊與即時回饋的線上監考系統", "考試將不再受場地與時間所限制"],
            imgStyle: caseImg2,
        },
        {
            title: "影音管理系統",
            text: ["豐富的即時觀看服務平台", "支持影音等多種內容的管理系統"],
            imgStyle: caseImg3,
        },
        {
            title: "電子票卡系統",
            text: ["一站式自助報到的解決方案", "方便快捷"],
            imgStyle: caseImg4,
        },
        {
            title: "人工智慧服務",
            text: ["人工智慧服務運用先進技術進行數據分析", "協助自動化並優化業務操作和客戶互動"],
            imgStyle: caseImg5,
        },
        {
            title: "網頁規劃服務",
            text: ["網頁架設、預約系統、顧客管理", "我們一手包辦"],
            imgStyle: caseImg6,
        },
    ];
    function CaseItem({ title, text, imgStyle }) {
        return (
            <div className="item">
                <div className="caseImg">
                    <div className="img" style={imgStyle}></div>
                </div>
                <div className="caseTitle">{title}</div>
                <div className="caseText">
                    {text.map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div id="servicesPage" className="servicesPage pageSettings">
            <Navbar />

            <div className="ipadBK ipad" style={BK2}>
                <div className="blackBlock"></div>
                <div className="textBox">
                    <div className="text">服務項目</div>
                    <div className="text2">確保客戶的需求</div>
                    <div className="text2">我們一路相伴</div>
                </div>
            </div>
            <section className="mainPage">
                <div className="content">
                    <div className="contentData">
                        <div className="BKImg pc" style={BK1}>
                            <div className="blackBlock"></div>
                            <div className="textBox">
                                <div className="title mb-3">服務項目</div>
                                <div className="text">確保客戶的需求</div>
                                <div className="text">得到最完善的實現</div>
                                <div className="text">我們一路相伴</div>
                            </div>
                        </div>
                        <div className="dataInfo">
                            <div className="case">
                                {cases.map((caseItem, index) => (
                                    <CaseItem key={index} title={caseItem.title} text={caseItem.text} imgStyle={caseItem.imgStyle} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default ServicesPage;
