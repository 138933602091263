import React from "react";

const footer = () => {
    return (
        <footer className="footer">
            <div className="copyright">
                © 2021-2024 White Blocktech <span className="pc">白塊科技</span> All Rights Reserved
            </div>
        </footer>
    );
};
export default footer;
