import React, { useEffect } from "react";
import Navbar from "./navbar";
import Footer from "./footer";

import Background from "../assets/img/about.jpg";
import Background2 from "../assets/img/aboutHalf.jpg";

function AboutPage() {
    const phoneBK = { backgroundImage: `url(${Background})` };
    useEffect(() => {
        const ogDescription = document.querySelector('meta[property="og:description"]');
        if (ogDescription) {
            ogDescription.setAttribute("content", "white block - 關於我們");
        }
    }, []);

    return (
        <div id="aboutPage" className="aboutPage pageSettings">
            <Navbar />
            <div className="ipadBK ipad" style={phoneBK}>
                <div className="blackBlock"></div>
                <div className="textBox">
                    <div className="text">關於我們</div>
                    <div className="text2">ABOUT US - WHITEBLOCK TECH</div>
                </div>
            </div>
            <section className="mainPage">
                <div className="content">
                    <div className="contactData">
                        <div className="dataInfo">
                            <div className="title">
                                <div>
                                    Hello,
                                    <wbr />
                                    我們是白塊科技
                                </div>
                            </div>
                            <div className="textBox-wrap">
                                <div className="description">
                                    <div className="subtitle">
                                        <div className="titleBox">
                                            <span>關於白塊科技</span>
                                            <span>我們提供</span>
                                            <wbr className="pc" />
                                            <span>系統及網頁建置的客製化服務</span>
                                        </div>
                                        <div className="pc">WHITE BLOCK - Custom Design and Development</div>
                                    </div>

                                    <div className="textBox">
                                        <div>我們成立於2021年</div>
                                        <div>是由一群熱愛程式的年輕人所組成</div>
                                        <div>願我們能夠透過</div>
                                        <div>強大的人工智慧與程式能力讓生活更加便利</div>
                                        <div>打造更美好的生活</div>
                                        <div>建造不一樣的科技世代</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="BKImg pc">
                            <img src={Background2}></img>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default AboutPage;
