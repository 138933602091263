import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/css/main.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
reportWebVitals();
