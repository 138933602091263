import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "./navbar";
import HomeFooter from "./homeFooter";
import Background from "../assets/img/background.jpg";
import BackgroundPhone from "../assets/img/phoneBackground.jpg";
import BackgroundPhoneBlack from "../assets/img/phoneBackgroundBlack.jpg";
import Border from "../assets/img/border.png";
import CaseImg1 from "../assets/img/services1.png";
import CaseImg2 from "../assets/img/services2.png";
import CaseImg3 from "../assets/img/services3.png";
import CaseImg4 from "../assets/img/services4.png";
import CaseImg5 from "../assets/img/services5.png";
import CaseImg6 from "../assets/img/services6.png";
import ToTop from "../assets/img/toTop.png";

const HomePage = () => {
    const [background, setBackground] = useState(window.innerWidth);
    const updateBG = () => {
        setBackground(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", updateBG);
        return () => window.removeEventListener("resize", updateBG);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const BGImg =
        background <= 576
            ? { backgroundImage: `url(${BackgroundPhone})` }
            : { backgroundImage: `url(${Background})` };
    const BorderImg = { backgroundImage: `url(${Border})` };
    const BGBImg = { backgroundImage: `url(${BackgroundPhoneBlack})` };
    const caseImg1 = { backgroundImage: `url(${CaseImg1})` };
    const caseImg2 = { backgroundImage: `url(${CaseImg2})` };
    const caseImg3 = { backgroundImage: `url(${CaseImg3})` };
    const caseImg4 = { backgroundImage: `url(${CaseImg4})` };
    const caseImg5 = { backgroundImage: `url(${CaseImg5})` };
    const caseImg6 = { backgroundImage: `url(${CaseImg6})` };
    const description = "白塊科技有限公司";
    const cases = [
        {
            title: "MOOCS平台",
            text: ["打造開放形式的線上學習平台", "學習者可以透過行動裝置與個人電腦進行學習"],
            imgStyle: caseImg1,
        },
        {
            title: "遠距監考系統",
            text: ["一套結合視訊與即時回饋的線上監考系統", "考試將不再受場地與時間所限制"],
            imgStyle: caseImg2,
        },
        {
            title: "影音管理系統",
            text: ["豐富的即時觀看服務平台", "支持影音等多種內容的管理系統"],
            imgStyle: caseImg3,
        },
        {
            title: "電子票卡系統",
            text: ["一站式自助報到的解決方案", "方便快捷"],
            imgStyle: caseImg4,
        },
        {
            title: "人工智慧服務",
            text: ["人工智慧服務運用先進技術進行數據分析", "協助自動化並優化業務操作和客戶互動"],
            imgStyle: caseImg5,
        },
        {
            title: "網頁規劃服務",
            text: ["網頁架設、預約系統、顧客管理", "我們一手包辦"],
            imgStyle: caseImg6,
        },
    ];
    function CaseItem({ title, text, imgStyle }) {
        return (
            <div className="item">
                <div className="caseImg">
                    <div className="img" style={imgStyle}></div>
                </div>
                <div className="caseTitle">{title}</div>
                <div className="caseText">
                    {text.map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>
            </div>
        );
    }
    return (
        <main>
            <Helmet>
                <meta property="og:description" content={description} />
            </Helmet>
            <div id="homePage" className="homePage pageSettings">
                <Navbar />
                <div className="whiteBlock content ipad"></div>
                <section className="mainPage" style={BGImg}>
                    <div className="content-wrap" style={BorderImg}>
                        <div className="content">
                            <div className="title">數位訂製</div>
                            <div className="title">量身策畫</div>
                            <div className="subtitle">透過雲端運算和AI打造成熟可靠的解決方案</div>
                            <button className="contactBtn btn">
                                <Link to="/contactUs">
                                    立即聯絡我們 <i className="bi bi-arrow-right-short"></i>
                                </Link>
                            </button>
                        </div>
                    </div>
                </section>
                <section className="phonePage">
                    <div className="content ipad">
                        <div className="whiteBlock"></div>
                        <div className="services" style={BGBImg}>
                            <div className="content">
                                <div className="title">我們提供系統建置服務</div>
                                <div className="subtitle">打造亮眼、專業資訊系統平台</div>
                                <div className="subtitle mt-0">提供全面整合解決策略</div>
                                <button className="contactBtn btn">
                                    <Link to="/about">
                                        關於白塊 <i className="bi bi-arrow-right-short"></i>
                                    </Link>
                                </button>
                            </div>
                        </div>
                        <div className="case">
                            <div className="content">
                                <div className="title">服務項目</div>
                                <div className="subtitle">確保客戶的需求得到最完善的實現</div>
                                <div className="subtitle mt-0 mb-5">我們一路相伴</div>
                                {cases.map((caseItem, index) => (
                                    <CaseItem
                                        key={index}
                                        title={caseItem.title}
                                        text={caseItem.text}
                                        imgStyle={caseItem.imgStyle}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="grayBlock">
                            <div className="toTop item" onClick={scrollToTop}>
                                Top<img className="ms-2" src={ToTop} alt="ToTop"></img>
                            </div>
                        </div>
                    </div>
                </section>
                <HomeFooter />
            </div>
        </main>
    );
};

export default HomePage;
