import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import LogoImg from "../assets/img/logo.png";
import TogglerIcon from "../assets/img/toggler-icon.png";

function Navbar() {
    const IconImg = { backgroundImage: `url(${TogglerIcon})` };
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    return (
        <nav className="navbar navbar-expand-lg navbar-drank">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/">
                    <img src={LogoImg} className="me-2" alt="logoImg" />
                    <span className="me-3">WHITEBLOCK</span>
                    <span>TECH</span>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={handleNavCollapse}
                    aria-expanded={!isNavCollapsed}
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" style={IconImg}></span>
                </button>
                <div className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`} id="navbarSupportedContent">
                    <ul className="navbar-nav">
                        <li className="nav-item me-5">
                            <NavLink className="nav-link" to="/about">
                                關於白塊
                            </NavLink>
                        </li>
                        <li className="nav-item me-5">
                            <NavLink className="nav-link" to="/services">
                                服務項目
                            </NavLink>
                        </li>
                        <li className="nav-item me-5">
                            <NavLink className="nav-link" to="/case">
                                案例介紹
                            </NavLink>
                        </li>
                        <li className="nav-item me-5">
                            <NavLink className="nav-link" to="/contactUs">
                                聯絡我們
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
export default Navbar;
