import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import Background from "../assets/img/caseHalf.jpg";
import Background2 from "../assets/img/services.jpg";
import CaseImg1 from "../assets/img/case1.png";
import CaseImg2 from "../assets/img/case2.png";
import CaseImg3 from "../assets/img/case3.jpg";
import CaseImg4 from "../assets/img/case4.jpg";
import CaseImg5 from "../assets/img/case5.png";
import CaseImg6 from "../assets/img/case6.png";

function CasePage() {
    const BK1 = { backgroundImage: `url(${Background})` };
    const BK2 = { backgroundImage: `url(${Background2})` };
    const caseImg1 = { backgroundImage: `url(${CaseImg1})` };
    const caseImg2 = { backgroundImage: `url(${CaseImg2})` };
    const caseImg3 = { backgroundImage: `url(${CaseImg3})` };
    const caseImg4 = { backgroundImage: `url(${CaseImg4})` };
    const caseImg5 = { backgroundImage: `url(${CaseImg5})` };
    const caseImg6 = { backgroundImage: `url(${CaseImg6})` };

    const cases = [
        {
            title: "清華雲MOOCS平台",
            text: ["國立清華大學MOOCs平台"],
            imgStyle: caseImg1,
            url: "https://mooc.nthu.edu.tw",
        },
        {
            title: "HEIMDALLR遠距監考系統",
            text: ["結合真人線上遠端監控之遠距考試系統"],
            imgStyle: caseImg2,
            url: "https://www.heimdallrexam.com/",
        },
        {
            title: "華南HNWU公會平台系統",
            text: ["華南HNWU公會的高效帳務管理與訊息交流平台"],
            imgStyle: caseImg3,
        },
        {
            title: "兆基入居者審查系統",
            text: ["快速、準確、安全驗證入居者是否擁有良好記錄"],
            imgStyle: caseImg4,
        },
        {
            title: "影片字幕",
            text: ["運用人工智慧迅速產生多國語言字幕，與方便替換字幕的系統"],
            imgStyle: caseImg5,
        },
        {
            title: "設計師客戶儲值管理系統",
            text: ["髮型設計師的專屬介紹與顧客儲值管理"],
            imgStyle: caseImg6,
        },
    ];
    function CaseItem({ title, text, imgStyle, url }) {
        return (
            <div className="item">
                <div className="caseImg">
                    <div className="img" style={imgStyle}></div>
                </div>
                <div className="caseTitle">{title}</div>
                <div className="caseText">
                    {text.map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </div>

                {url && (
                    <div className="caseUrl">
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            了解更多內容 {" >>"}
                        </a>
                    </div>
                )}
            </div>
        );
    }
    return (
        <div id="casePage" className="casePage servicesPage pageSettings">
            <Navbar />

            <div className="ipadBK ipad" style={BK2}>
                <div className="blackBlock"></div>
                <div className="textBox">
                    <div className="text">案例介紹</div>
                    <div className="text2">專為您打造專屬系統</div>
                </div>
            </div>
            <section className="mainPage">
                <div className="content">
                    <div className="contentData">
                        <div className="BKImg pc" style={BK1}>
                            <div className="blackBlock"></div>
                            <div className="textBox">
                                <div className="title mb-3">案例介紹</div>
                                <div className="text">專為您打造專屬系統</div>
                                <div className="text">提供量身訂做的解決方案</div>
                            </div>
                        </div>
                        <div className="dataInfo">
                            <div className="case">
                                {cases.map((caseItem, index) => (
                                    <CaseItem
                                        key={index}
                                        title={caseItem.title}
                                        text={caseItem.text}
                                        imgStyle={caseItem.imgStyle}
                                        url={caseItem.url}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}

export default CasePage;
